.navMenu {
  &__container {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 5px;
    padding-top: 12px;
  }
  &__item {
    font-size: 16px;
  }
}

@media screen and (max-width: 640px) {
  .navMenu {
    &__container {
      padding-top: 5px;
    }
  }
}
